import React from "react";
import { Link } from "react-router-dom";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";

const Payment = () => {
  return (
    <div className="max-w-container mx-auto px-4">
      <Breadcrumbs title="Payment gateway" />
      <div className="pb-10">
        <p>Payment method unavailable at the moment. Please hold on shortly, meanwhile payment on delivery is fully availab;e for usage</p>
        <Link to="/">
          <button className="w-52 h-10 bg-primeColor text-white text-lg mt-4 hover:bg-black duration-300">
            Continue to shop
          </button>
        </Link> 
      </div>
    </div>
  );
};

export default Payment;
